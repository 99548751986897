import { analytics } from '../segment';

export function homepageAnalytics() {
  return {
    track: (section: string, title: string) => {
      analytics.track('Homepage Interaction', {
        accordion_question: title,
        homepage_section: section,
      });
    },
  };
}
