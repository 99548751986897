import { useState } from 'react';
import { useMedia } from 'react-use';
import { Heading, HeadingVariant, Paragraph } from '@hungryroot/ramen';
import { homepageAnalytics } from '@hungryroot/segment';
import { BreakpointQueryLarge } from '@hungryroot/tokens';
import { Accordion } from '../Accordion/Accordion';
import styles from './HowItWorks.module.css';

type ImageDefinition = {
  src: string;
  height: number;
  width: number;
};

type ResponsiveImageDefinition = {
  mobile: ImageDefinition;
  desktop: ImageDefinition;
};

type Props = {
  getPersonalRecommendationsImage: ResponsiveImageDefinition;
  yourPersonalizedPlanImage: ResponsiveImageDefinition;
  healthyDeliveriesImage: ResponsiveImageDefinition;
};

type CardContent = {
  id: string;
  title: string;
  image: ResponsiveImageDefinition;
  copy: string;
};

export function HowItWorks(props: Props) {
  const {
    getPersonalRecommendationsImage,
    yourPersonalizedPlanImage,
    healthyDeliveriesImage,
  } = props;
  const isDesktop = useMedia(BreakpointQueryLarge, false);
  const segmentEvents = homepageAnalytics();

  const content = {
    heading: 'Meet SmartCart™',
    subheading:
      'A first-of-its-kind grocery technology that helps you save time and eat healthy.',
    cardContent: [
      {
        id: 'tell',
        title: 'Get personal recommendations',
        image: getPersonalRecommendationsImage,
        copy: `SmartCart™ uses your preferences, feedback, and ordering behavior to fill your cart with food you’ll love.`,
      },
      {
        id: 'personalized',
        title: 'Customize your delivery',
        image: yourPersonalizedPlanImage,
        copy: `Take our suggestions or edit your cart to get exactly what you want each week.`,
      },
      {
        id: 'healthy',
        title: 'See SmartCart™ get smarter',
        image: healthyDeliveriesImage,
        copy: `Save time and stress as SmartCart™ gets to know you better and predicts your perfect cart for each delivery.`,
      },
    ],
  };

  const [active, setActive] = useState<CardContent['id']>('tell');

  if (content === undefined) {
    return;
  }

  return (
    <div className={styles.outer}>
      <section className={styles.container}>
        <div className={styles['content-container']}>
          <div className={styles.header}>
            <Heading variant={HeadingVariant.HeroAlt}>
              {content.heading}
            </Heading>
            {content.subheading && (
              <Paragraph className={styles.subheading}>
                {content.subheading}
              </Paragraph>
            )}
          </div>

          <ul className={styles['accordion-list-container']}>
            {content.cardContent.map(({ id, title, image, copy }) => {
              const isExpanded = id === active;
              return (
                <li className={styles.item} key={id}>
                  <Accordion
                    isExpanded={isExpanded}
                    onToggle={({ expanded }) => {
                      if (expanded === true) {
                        setActive(id);

                        segmentEvents.track('SmartCart', title);
                      }
                    }}
                    buttonClassName={styles['accordion-button']}
                    className={styles['accordion-container']}
                    headingClassName={styles['accordion-title']}
                    bodyClassName={styles['accordion-body']}
                    title={
                      <Heading variant={HeadingVariant.H3}>{title}</Heading>
                    }
                  >
                    <figure className={styles.figure}>
                      {isDesktop === false && (
                        <div
                          className={`${styles.image} ${
                            styles[`mobile-image-${id}`]
                          }`}
                        >
                          <img
                            loading="lazy"
                            src={image.mobile.src}
                            height={image.mobile.height}
                            width={image.mobile.width}
                            alt=""
                          />
                        </div>
                      )}
                      <figcaption className={styles.figcaption}>
                        <Paragraph>{copy}</Paragraph>
                      </figcaption>
                    </figure>
                  </Accordion>
                </li>
              );
            })}
          </ul>
        </div>
        {isDesktop === true && (
          <div>
            {content.cardContent.map(({ id, image }) => {
              const isActive = active === id;
              const activeClassName =
                isActive === true
                  ? styles['desktop-image-active']
                  : styles['desktop-image'];
              return (
                <div
                  className={`${activeClassName} ${
                    styles[`image-desktop-${id}`]
                  }`}
                  key={id}
                >
                  <img
                    loading="lazy"
                    src={image.desktop.src}
                    height={image.desktop.height}
                    width={image.desktop.width}
                    alt=""
                  />
                </div>
              );
            })}
          </div>
        )}
      </section>
    </div>
  );
}
