import { type ReactNode, useEffect, useRef } from 'react';
import { motion, useInView, useReducedMotion } from 'framer-motion';
import { Heading, HeadingVariant } from '@hungryroot/ramen';
import { isHeroButtonInView } from '../../../homepageStore';
import { TextLoop } from '../../TextLoop/TextLoop';
import { dynamicText } from '../DefaultHomeHero';
import styles from './StaticHomeHero.module.css';

export type HomeHeroProps = {
  imageSources: {
    srcSet: string;
    media: string;
    width: number;
    height: number;
  }[];
  image: {
    srcSet: string;
    src: string;
    height: number;
    width: number;
  };
  children: ReactNode;
  resumeQuizButton: ReactNode;
};

type Props = HomeHeroProps & {
  heading: string;
};

export function StaticHomeHero(props: Props) {
  const {
    imageSources,
    image: {
      srcSet: imageSrcSet,
      src: imageSrc,
      width: imageWidth,
      height: imageHeight,
    },
    children,
    resumeQuizButton,
    heading,
  } = props;
  const shouldReduceMotion = useReducedMotion();

  const buttonRef = useRef(null);
  const isButtonInView = useInView(buttonRef);

  useEffect(() => {
    // Update store
    isHeroButtonInView.set(isButtonInView);
  }, [isButtonInView]);

  return (
    <div className={styles.container}>
      {children}
      <motion.picture
        initial={
          shouldReduceMotion
            ? { opacity: 0, scale: 1 }
            : { opacity: 0, scale: 1.02 }
        }
        animate={shouldReduceMotion ? { opacity: 1 } : { opacity: 1, scale: 1 }}
        transition={{ duration: 0.45 }}
        className={styles.picture}
      >
        {imageSources.map(({ media, srcSet, width, height }) => {
          return (
            <source
              key={media}
              srcSet={srcSet}
              media={media}
              width={width}
              height={height}
            />
          );
        })}
        <img
          srcSet={imageSrcSet}
          decoding="async"
          height={imageHeight}
          width={imageWidth}
          className={styles.image}
          src={imageSrc}
          alt="Hungryroot Products"
        />
      </motion.picture>
      <motion.div
        className={styles['container-inner']}
        initial={shouldReduceMotion ? { opacity: 0 } : { opacity: 0, y: '8px' }}
        animate={shouldReduceMotion ? { opacity: 1 } : { opacity: 1, y: 0 }}
        transition={{ delay: 0.4, duration: 0.45 }}
      >
        <Heading variant={HeadingVariant.HeroAlt} className={styles.heading}>
          {heading}
        </Heading>
        <div className={styles.subheading}>
          <TextLoop
            staticLabel="The best"
            dynamicContent={dynamicText}
            contentClassName={styles.demi}
          />
          <span className={styles['subheading-static']}>
            <span>for you and your family.</span>
          </span>
        </div>
        <span ref={buttonRef}>{resumeQuizButton}</span>
      </motion.div>
    </div>
  );
}
