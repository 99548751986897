import { useState, type ReactNode } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Collection,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  type Key,
} from 'react-aria-components';
import { useMedia } from 'react-use';
import useMeasure from 'react-use-measure';
import { Pill, Heading, HeadingVariant, Paragraph } from '@hungryroot/ramen';
import { BreakpointQueryLarge } from '@hungryroot/tokens';
import { TasteAndNeedsCard } from '../TasteAndNeedsCard/TasteAndNeedsCard';

import { AntiInflammatory as AntiInflammatoryRecipe } from './svg/recipe/AntiInflammatory';
import { DairyFree as DairyFreeRecipe } from './svg/recipe/DairyFree';
import { GlutenFree as GlutenFreeRecipe } from './svg/recipe/GlutenFree';
import { GutFriendly as GutFriendlyRecipe } from './svg/recipe/GutFriendly';
import { LessSugar as LessSugarRecipe } from './svg/recipe/LessSugar';
import { Protein as ProteinRecipe } from './svg/recipe/Protein';
import { Vegetarian as VegetarianRecipe } from './svg/recipe/Vegetarian';
import { AntiInflammatory } from './svg/tabs/AntiInflammatory';
import { DairyFree } from './svg/tabs/DairyFree';
import { GlutenFree } from './svg/tabs/GlutenFree';
import { GutFriendly } from './svg/tabs/GutFriendly';
import { LessSugar } from './svg/tabs/LessSugar';
import { Protein } from './svg/tabs/Protein';
import { Vegetarian } from './svg/tabs/Vegetarian';

import { AntiInflammatory as AntiInflammatoryTitle } from './svg/title/AntiInflammatory';
import { DairyFree as DairyFreeTitle } from './svg/title/DairyFree';
import { GlutenFree as GlutenFreeTitle } from './svg/title/GlutenFree';
import { GutFriendly as GutFriendlyTitle } from './svg/title/GutFriendly';
import { LessSugar as LessSugarTitle } from './svg/title/LessSugar';
import { Protein as ProteinTitle } from './svg/title/Protein';
import { Vegetarian as VegetarianTitle } from './svg/title/Vegetarian';

import styles from './TastesAndNeeds.module.css';

type Props = {
  antiInflammatoryImage: ReactNode;
  highProteinImage: ReactNode;
  glutenFreeImage: ReactNode;
  gutFriendlyImage: ReactNode;
  vegerarianImage: ReactNode;
  dairyFreeImage: ReactNode;
  lessSugarImage: ReactNode;
  plusMoreImage: ReactNode;
  resumeQuizButton: ReactNode;
  findYourPlanButton: ReactNode;
};

function getTabs<D>(base: D[]) {
  return [...base];
}

export function TastesAndNeeds({
  dairyFreeImage,
  highProteinImage,
  gutFriendlyImage,
  vegerarianImage,
  glutenFreeImage,
  antiInflammatoryImage,
  lessSugarImage,
  plusMoreImage,
  resumeQuizButton,
  findYourPlanButton,
}: Props) {
  const [selected, setSelected] = useState<Key>('Anti-inflammatory');
  const [tabContainerRef, tabContainerMeasure] = useMeasure();
  const isDesktop = useMedia(BreakpointQueryLarge, false);

  const selectableTabs = [
    {
      id: 'Anti-inflammatory',
      label: 'Anti-Inflammatory',
      measure: useMeasure(),
      tabIcon: <AntiInflammatory />,
      content: (
        <TasteAndNeedsCard
          body={
            <Paragraph className={styles.copy}>
              Fill your cart with delicious, whole foods packed with
              inflammation-fighting benefits—think fruits, veggies, lean
              proteins, whole grains, and healthy fats.
            </Paragraph>
          }
          header={{
            text: 'Anti-Inflammatory',
            icon: <AntiInflammatoryTitle />,
          }}
          image={antiInflammatoryImage}
          recipesSummary={{
            text: '1,000+ recipes',
            illustration: <AntiInflammatoryRecipe />,
            caption: 'Easy meals ready in minutes',
          }}
          resumeQuizButton={resumeQuizButton}
        />
      ),
    },
    {
      id: 'high protein',
      label: 'High Protein',
      measure: useMeasure(),
      tabIcon: <Protein />,
      content: (
        <TasteAndNeedsCard
          body={
            <Paragraph className={styles.copy}>
              Hitting your protein goals is easy with premium meats, fresh
              seafood, and plant-based options that keep your body energized and
              your taste buds happy.
            </Paragraph>
          }
          header={{
            text: 'High Protein',
            icon: <ProteinTitle />,
          }}
          image={highProteinImage}
          recipesSummary={{
            text: '9,000+ recipes',
            illustration: <ProteinRecipe />,
            caption: 'Easy meals ready in minutes',
          }}
          resumeQuizButton={resumeQuizButton}
        />
      ),
    },
    {
      id: 'gluten-free',
      label: 'Gluten-Free',
      tabIcon: <GlutenFree />,
      measure: useMeasure(),
      content: (
        <TasteAndNeedsCard
          body={
            <Paragraph className={styles.copy}>
              Eating gluten-free shouldn’t mean giving up deliciousness. We’ve
              got tons of tasty options that make it easy to give up gluten.
            </Paragraph>
          }
          header={{
            text: 'Gluten-Free',
            icon: <GlutenFreeTitle />,
          }}
          image={glutenFreeImage}
          recipesSummary={{
            text: '6,000+ recipes',
            illustration: <GlutenFreeRecipe />,
            caption: 'Easy meals ready in minutes',
          }}
          resumeQuizButton={resumeQuizButton}
        />
      ),
    },
    {
      id: 'gut-friendly',
      label: 'Gut-Friendly',
      tabIcon: <GutFriendly />,
      measure: useMeasure(),
      content: (
        <TasteAndNeedsCard
          body={
            <Paragraph className={styles.copy}>
              Take control of your gut health with foods that support a happy,
              thriving gut—think microbiome-nurturing nutrients like fiber,
              antioxidants, and healthy fats.
            </Paragraph>
          }
          header={{
            text: 'Gut-Friendly',
            icon: <GutFriendlyTitle />,
          }}
          image={gutFriendlyImage}
          recipesSummary={{
            text: '1,200+ recipes',
            illustration: <GutFriendlyRecipe />,
            caption: 'Easy meals ready in minutes',
          }}
          resumeQuizButton={resumeQuizButton}
        />
      ),
    },
    {
      id: 'vegetarian',
      label: 'Vegetarian',
      tabIcon: <Vegetarian />,
      measure: useMeasure(),
      content: (
        <TasteAndNeedsCard
          body={
            <Paragraph className={styles.copy}>
              No food boredom here! With fresh produce, plant-based proteins,
              and so many other veg-friendly eats to choose from, eating
              vegetarian has never been easier (or tastier).
            </Paragraph>
          }
          header={{
            text: 'Vegetarian',
            icon: <VegetarianTitle />,
          }}
          image={vegerarianImage}
          recipesSummary={{
            text: '3,800+ recipes',
            illustration: <VegetarianRecipe />,
            caption: 'Easy meals ready in minutes',
          }}
          resumeQuizButton={resumeQuizButton}
        />
      ),
    },
    {
      id: 'less sugar',
      label: 'Less Sugar',
      tabIcon: <LessSugar />,
      measure: useMeasure(),
      content: (
        <TasteAndNeedsCard
          body={
            <Paragraph className={styles.copy}>
              Avoiding sneaky sugar is a piece of cake thanks to hundreds of
              delicious recipes that are low in sugar—but big on flavor.
            </Paragraph>
          }
          header={{
            text: 'Less Sugar',
            icon: <LessSugarTitle />,
          }}
          image={lessSugarImage}
          recipesSummary={{
            text: '10,000+ recipes',
            illustration: <LessSugarRecipe />,
            caption: 'Easy meals ready in minutes',
          }}
          resumeQuizButton={resumeQuizButton}
        />
      ),
    },
    {
      id: 'dairy free',
      label: 'Dairy-Free',
      tabIcon: <DairyFree />,
      measure: useMeasure(),
      content: (
        <TasteAndNeedsCard
          body={
            <Paragraph className={styles.copy}>
              Flavorful recipes and dairy-free versions of your favorite foods
              prove that living without dairy can be just as delicious.
            </Paragraph>
          }
          header={{
            text: 'Dairy-Free',
            icon: <DairyFreeTitle />,
          }}
          image={dairyFreeImage}
          recipesSummary={{
            text: '8,000+ recipes',
            illustration: <DairyFreeRecipe />,
            caption: 'Easy meals ready in minutes',
          }}
          resumeQuizButton={resumeQuizButton}
        />
      ),
    },
    {
      id: '13 more',
      label: '+13 more',
      tabIcon: <></>,
      measure: useMeasure(),
      content: (
        <TasteAndNeedsCard
          body={
            <div className={styles['card-body-grid']}>
              <div className={styles['list-container']}>
                <Heading
                  variant={HeadingVariant.H6}
                  className={styles['list-heading']}
                >
                  Goals
                </Heading>
                <ul className={styles.list}>
                  <li className={styles.item}>
                    <Paragraph className={styles['item-content']}>
                      Less Prep Time
                    </Paragraph>
                  </li>
                  <li className={styles.item}>
                    <Paragraph className={styles['item-content']}>
                      Immunity-Boosting
                    </Paragraph>
                  </li>
                  <li className={styles.item}>
                    <Paragraph className={styles['item-content']}>
                      Carb Conscious
                    </Paragraph>
                  </li>
                  <li className={styles.item}>
                    <Paragraph className={styles['item-content']}>
                      Less Sodium
                    </Paragraph>
                  </li>
                </ul>
              </div>
              <div className={styles['list-container']}>
                <Heading
                  variant={HeadingVariant.H6}
                  className={styles['list-heading']}
                >
                  Restrictions
                </Heading>
                <ul className={styles.list}>
                  <li className={styles.item}>
                    <Paragraph className={styles['item-content']}>
                      Food & Flavor Preferences
                    </Paragraph>
                  </li>
                  <li className={styles.item}>
                    <Paragraph className={styles['item-content']}>
                      Exclude Specific Foods
                    </Paragraph>
                  </li>
                  <li className={styles.item}>
                    <Paragraph className={styles['item-content']}>
                      Peanut-Free
                    </Paragraph>
                  </li>
                  <li className={styles.item}>
                    <Paragraph className={styles['item-content']}>
                      Tree Nut-Free
                    </Paragraph>
                  </li>
                  <li className={styles.item}>
                    <Paragraph className={styles['item-content']}>
                      Soy-Free
                    </Paragraph>
                  </li>
                  <li className={styles.item}>
                    <Paragraph className={styles['item-content']}>
                      Egg-Free
                    </Paragraph>
                  </li>
                  <li className={styles.item}>
                    <Paragraph className={styles['item-content']}>
                      Shellfish-Free
                    </Paragraph>
                  </li>
                </ul>
              </div>
              <div className={styles['list-container']}>
                <Heading
                  variant={HeadingVariant.H6}
                  className={styles['list-heading']}
                >
                  Diets
                </Heading>
                <ul className={styles.list}>
                  <li className={styles.item}>
                    <Paragraph className={styles['item-content']}>
                      Pescatarian
                    </Paragraph>
                  </li>
                  <li className={styles.item}>
                    <Paragraph className={styles['item-content']}>
                      Vegan
                    </Paragraph>
                  </li>
                </ul>
              </div>
            </div>
          }
          header={{
            text: 'We also support:',
            icon: <></>,
          }}
          image={plusMoreImage}
          resumeQuizButton={findYourPlanButton}
        />
      ),
    },
  ];

  const selectedTab = selectableTabs.find(({ id }) => id === selected);
  const selectedMeasure = selectedTab?.measure[1];
  const tabs = getTabs(selectableTabs);

  return (
    <section className={styles.container}>
      <Heading className={styles.header} variant={HeadingVariant.HeroAlt}>
        Tailored to your tastes and needs
      </Heading>
      <div className={styles.background}>
        <Tabs
          selectedKey={selected}
          onSelectionChange={(key) => setSelected(key)}
          className={styles.tabs}
        >
          <div ref={tabContainerRef} className={styles['tablist-container']}>
            {isDesktop &&
              selectedMeasure !== undefined &&
              selectedMeasure.left !== 0 && (
                <AnimatePresence initial={false}>
                  <motion.div
                    transition={{
                      type: 'spring',
                      mass: 1,
                      damping: 42,
                      stiffness: 500,
                    }}
                    animate={{
                      width: `${selectedMeasure.width}px`,
                      height: `${selectedMeasure.height}px`,
                      transform: `translateX(${
                        selectedMeasure.left - tabContainerMeasure.left
                      }px)`,
                    }}
                    className={styles['pill-background']}
                  >
                    <Pill className={styles['pill-background-pill']} label="" />
                  </motion.div>
                </AnimatePresence>
              )}
            <TabList className={styles.tablist} items={tabs}>
              {({ label: tabLabel, id: tabId, measure, tabIcon }) => {
                let tabClassName =
                  tabId === selected
                    ? `${styles.pill} ${styles.active}`
                    : styles.pill;
                return (
                  <Tab
                    ref={measure === undefined ? null : measure[0]}
                    className={styles.tab}
                  >
                    <Paragraph
                      className={styles['pill-container']}
                      variant="caption"
                    >
                      <Pill
                        iconPosition="left"
                        icon={tabIcon}
                        className={tabClassName}
                        label={tabLabel}
                      />
                    </Paragraph>
                  </Tab>
                );
              }}
            </TabList>
          </div>
          <Collection items={tabs}>
            {(item) => {
              const className =
                item.id === selected
                  ? styles['tab-panel-active']
                  : styles['tab-panel'];
              return (
                <TabPanel shouldForceMount={true} className={className}>
                  {item.content}
                </TabPanel>
              );
            }}
          </Collection>
        </Tabs>
      </div>
    </section>
  );
}
